import React from 'react';
import { string, shape, object } from 'prop-types';
import { injectI18n } from 'nordic/i18n';
import { Image } from 'nordic/image';

import Section from '../section';

const namespace = 'financial-user-info';

const FinancialUserInfo = ({ type, picture, url, deviceType, i18n }) => {
  const height = deviceType === 'desktop' ? 56 : 58;
  const text = i18n.gettext('Usuarios Financieros');
  const accessibilityMessage = i18n.gettext('Se abrirá en una nueva ventana');
  const DESKTOP = 'desktop';
  const Element = deviceType === DESKTOP ? 'div' : Section;
  return (
    <Element className={type} data-testid={namespace}>
      <a
        className={`${namespace}__link`}
        href={url.target}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Image
          className={`${namespace}__image`}
          {...picture}
          height={height}
          alt={text}
        />
        <span className="clipped"> {accessibilityMessage}</span>
      </a>
    </Element>
  );
};

FinancialUserInfo.propTypes = {
  type: string.isRequired,
  picture: shape({
    src: string.isRequired,
  }).isRequired,
  url: shape({
    target: string.isRequired,
  }).isRequired,
  deviceType: string.isRequired,
  i18n: object.isRequired,
};

export default injectI18n(FinancialUserInfo);
